<script setup lang="ts">
import BaseButton from '/~/components/base/button/base-button'
import BaseHeader from '/~/components/base/header/base-header'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms'

const emit = defineEmits(['close', 'cancel', 'submit'])

const { ewalletLabels } = useCms()

function onClose() {
  emit('close')
}

function onCancel() {
  emit('cancel')
}

function onSubmit() {
  emit('submit')
}
</script>

<template>
  <div>
    <base-header
      type="closable"
      class="flex items-center justify-center p-7 pb-5 sm:pb-3"
      @close="onClose"
    >
      <div class="">
        <base-icon
          :size="64"
          svg="heroicons/solid/exclamation-circle"
          class="text-fg-error"
        />
      </div>
    </base-header>
    <div class="space-y-5 p-6 pt-0 sm:p-8 sm:pr-9 sm:pb-6 sm:pt-0">
      <div class="text-center text-2xl font-bold tracking-tight">
        Account Verification Required
      </div>

      <div class="pt-0 text-base sm:pt-6">
        To enhance security we have introduced a mandatory enrolment process.
        Until this process is completed successfully, certain platform
        functionalities are restricted.
        <br />
        <br />
        Please take a moment to complete the verification process to unlock this
        functionality.
      </div>

      <div class="text-base">
        <div class="mb-5 text-lg font-bold">How to Verify:</div>

        Click the ‘Verify now’ button below, or find the verification centre in
        {{ ewalletLabels.yourEwallet }} and follow the prompts to complete
        verification for both your mobile and email to enjoy all platform
        functionalities.
      </div>

      <div class="flex space-x-2 pt-2 sm:pt-7">
        <base-button class="flex-1" look="outlined-color" @click="onCancel">
          Later
        </base-button>
        <base-button class="flex-1" @click="onSubmit">Verify Now</base-button>
      </div>
    </div>
  </div>
</template>
